.testimonial-container {
    padding: 20px;
  }
  
  .testimonial-heading {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #00704A;
  }
  
  .slider-container {
    max-width: 960px;
    margin: 0 auto;
  }
  
  .testimonial-card {
    display: inline-block;
    width: 300px;
    margin-right: 10px;
  }
  
  .icon {
    color: #00704A;
    font-size: 16px;
  }
  
  .title {
    font-weight: bold;
    font-size: 18px;
  }
  
  .comment {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .details {
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .custom-light-green {
    color: #caf9f6; /* Light green color */
   
}

.font{
  font-size: 2.4em; /* Font size adjustment */
}

/* NewOfferings.css */

.card-container {
  width: 100%;
  margin-bottom: 20px;
}

.card {
  background-color: #fff;
  border-top: 2px solid #e2e8f0; /* Add top border */
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-details {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #718096;
}

.card-details span {
  margin-right: 5px;
}

.circle-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.circle {
  position: absolute;
  width: 200px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  border-radius: 50%;
  background-color: #fff; /* Adjust background color */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
}

.circle-1 {
  top: 50px; /* Adjust top position */
  left: calc(50% - 100px); /* Adjust left position */
}

.circle-2 {
  top: calc(50% - 100px); /* Adjust top position */
  left: 50px; /* Adjust left position */
}

.circle-3 {
  bottom: 50px; /* Adjust bottom position */
  left: calc(50% - 100px); /* Adjust left position */
}
