/* Add this CSS to your stylesheet */
.testimonial-card-wrapper {
    padding: 0 10px; /* Adjust spacing between cards */
 }
 
 .testimonial-card {
    border: 2px solid orange; /* Orange border */
    padding: 20px; /* Padding around content */
    border-radius: 10px; /* Rounded corners */
    background-color: #fff; /* White background */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
 }
 
 .testimonial-card p {
    margin-bottom: 10px; /* Spacing below paragraph */
 }
 
 .testimonial-card img {
    width: 50px; /* Adjust image size */
    height: 50px;
    border-radius: 50%; /* Make image circular */
 }
 