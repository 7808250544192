.service__item {
   padding      : 1rem;
   border-bottom: 1px solid #faa83586;
   border-right : 1px solid #faa83586;
   border-radius: 0.5rem;
}

.service__img {
   width          : 50px;
   height         : 50px;
   display        : flex;
   align-items    : center;
   justify-content: center;
   border-radius  : 50px;
   background     : var(--secondary-color);
   padding        : 0.5rem;
   margin-bottom  : 1rem;
   line-height    : 50px;
}

.service__img img {
   width: 100%;
}

.service__item h6 {
   font-size  : 1.1rem;
   font-weight: 500;
}

.service__item p {
   font-size: 0.8rem;
   color    : var(--text-color);
}

/* =============== RESPONSIVE ================ */
@media only screen and (max-width:992px) {
   
}


.service__card {
   border-radius: 10px;
   overflow: hidden;
   transition: all 0.3s ease;
 }
 
 .service__card:hover {
   transform: translateY(-5px);
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }
 
 .service__img {
   width: 100%;
   height: 150px;
   overflow: hidden;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
 }
 
 .service__img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
 }
 
 .service__content {
   padding: 20px;
 }
 
 .service__title {
   font-size: 1.5rem;
   margin-bottom: 10px;
   color: #333;
 }
 
 .service__desc {
   font-size: 1rem;
   color: #666;
 }
 
 .service__container {
   text-align: center;
   margin-bottom: 30px;
 }
 
 .service__title {
   font-size: 2rem;
   margin-bottom: 10px;
   color: #333;
 }
 
 .service__sub-title {
   font-size: 1.5rem;
   color: #666;
   margin-bottom: 50px;
 }
 
 .service__cards-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
 }
 
 .service__card {
   border-radius: 20px;
   overflow: hidden;
   transition: all 0.3s ease;
   margin: 0 10px;
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Added box shadow */
 }
 
 .service__card:hover {
   transform: translateY(-5px);
   box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Added larger shadow on hover */
 }
 
 .service__img {
   width: 100%;
   height: 200px;
   overflow: hidden;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
 }
 
 .service__img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
 }
 
 .service__content {
   padding: 20px;
 }
 
 .service__title {
   font-size: 1.5rem;
   margin-bottom: 10px;
   color: #333;
 }
 
 .service__desc {
   font-size: 1rem;
   color: #666;
 }
 
 .ant-card-meta-title,
 .ant-card-meta-description {
   font-size: 0.8rem;
 }
 
 .service__icon {
   font-size: 24px; /* Decreased icon size */
 }
 
 .service__card {
   border-radius: 20px;
   overflow: hidden;
   transition: all 0.3s ease;
   margin: 0 10px;
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Added box shadow */
 }
 
 .service__card:hover {
   transform: translateY(-5px);
   box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Added larger shadow on hover */
 }
 
 .service__img {
   width: 100%;
   height: 100px; /* Decreased image height */
   overflow: hidden;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
 }
 
 .service__img img {
   width: 50px; /* Decreased icon size */
   height: 50px; /* Decreased icon size */
   object-fit: cover;
 }
 
 .service__content {
   padding: 20px;
 }
 
 .service__title {
   font-size: 1.5rem;
   margin-bottom: 10px;
   color: #333;
 }
 
 .service__desc {
   font-size: 1rem;
   color: #666;
 }
 
 .container {
   max-width: 400px; /* Set a max-width */
   margin: 0 auto; /* Center the container */
 }
 