.tour__content img {
   width        : 100%;
   border-radius: 0.5rem;
   margin-bottom: 1rem;
}

.tour__info,
.tour__reviews {
   border-radius: 0.5rem;
   border       : 1px solid var(--secondary-color);
   padding      : 1.5rem;
}

.tour__info h2 {
   font-size: 1.5rem;
}

.tour__info span {
   display    : flex;
   align-items: center;
   column-gap : 0.5rem;
   font-size  : 0.8rem;
   color      : var(--text-color);
}

.tour__info span i {
   color    : var(--secondary-color);
   font-size: 1rem;
}

.tour__extra-details {
   display      : flex;
   align-items  : center;
   column-gap   : 2.5rem;
   margin-top   : 1rem;
   margin-bottom: 2.5rem;
}

.tour__info h5 {
   margin-bottom: 1rem;
   font-size    : 1.3rem;
}

.tour__info p {
   line-height: 30px;
   font-size  : 1rem;
   color      : var(--text-color);
}

.tour__reviews h4{
   margin-bottom: 1.5rem;
}

.rating__group span {
   font-size: 1rem;
   display: flex;
   align-items: center;
   color: var(--secondary-color);
   cursor: pointer;
}

.review__input {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   padding: 0.5rem;
   border-radius: 2rem;
   border: 1px solid var(--secondary-color);
}

.review__input input {
   width: 100%;
   padding: 0.5rem 0;
   border: none;
   outline: none;
}

.user__reviews {
   margin-top: 2.5rem;
}

.review__item {
   display: flex;
   align-items: center;
   column-gap: 1rem;
   margin-bottom: 2rem;
}

.review__item img {
   width: 3.5rem;
   height: 3.5rem;
   border-radius: 50px;
   object-fit: cover;
}

.review__item h5,
.review__item p,
.review__item h6 {
   font-size: 0.8rem;
   margin-bottom: 0;
}

.review__item p,
.review__item h6 {
   color: var(--text-color);
}

.review__item  span {
   font-weight: 500;
}

.review__item  span i {
   font-size: 1rem;
   color: var(--secondary-color);
}


/* =============== RESPONSIVE ================ */
@media only screen and (max-width: 768px) {
   .tour__extra-details {
      flex-wrap: wrap;
      row-gap: 1rem;
   }

   .tour__extra-details span {
      font-size: 0.9rem;
   }

   .tour__extra-details span i {
      font-size: 1rem;
   }
}  

.selected-photo-container {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.small-image {
   width: 100%;
   max-height: calc(100%/6); /* Divide the container height by 6 to display 6 images vertically */
}
.selected-photo {
   max-width: 100%;
   height: 380px; /* Set the desired fixed height for the selected photo */
}

.horizontal-divider {
   margin-top: 10px;
   margin-bottom: 10px;
   border: none;
   border-top: 2px solid #8e8989; /* Adjust color and style as needed */
}

/* Custom styling for Ant Design accordion */
.accordion-header {
   background: #fff;
   border-radius: 8px;
   padding: 16px;
   font-weight: bold;
   font-size: 18px;
   border: none;
 }
 
 .accordion-header:hover {
   background: #f0f0f0;
 }
 
 .accordion-panel {
   background: #fff;
   border-radius: 8px;
   margin-top: 8px;
   box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
 }
 
 .accordion-panel-content {
   padding: 16px;
 }
 
 /* customAccordion.css */

/* Style for the accordion container */
.custom-collapse {
   background-color: #fcfcfc;
   border-radius: 8px;
   overflow: hidden;
 }
 
 /* Style for the accordion panel headers */
 .custom-panel > .ant-collapse-header {
   background-color: #fcfcfc;
   color: #fff;
   font-weight: bold;
   border-radius: 8px;
 }
 
 /* Style for the accordion panel content */
 .custom-panel > .ant-collapse-content > .ant-collapse-content-box {
   padding: 20px;
   font-size: 16px;
   line-height: 1.5;
   color: #333;
 }
 